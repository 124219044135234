<template>
    <BasePoisonLocationInfoTest 
        :key="versionkeyreload"
        :PoisonLocationInfo="payload" 
        :entrycode="entrycode" 
        :passed="true" />
</template>

<script>
import { get } from 'vuex-pathify'

import UnsavedGuardMixin from '@/mixins/UnsavedGuard.js'
import PassEntrycode from '@/mixins/PassEntrycode.js'

export default {
    mixins: [UnsavedGuardMixin, PassEntrycode],
    computed: {
        ...get('version', {
            versionkeyreload: 'reload'
        }),
        payload() {
            let _payload = this.$store.state.incidentsnew.incident.data.PoisonLocationInfo
            return _payload
        }
    },
    methods: {
    },
}
</script>